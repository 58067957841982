const dev = {
  API_URL: "https://localhost:3000/v1",
  AUTH_URL: "https://localhost:3000",
  TOKEN_KEY: "ekauth:token",
  REFRESH_KEY: "ekauth:refreshToken",
  EXP_KEY: "ekauth:expiryDate",
  USER_INFO: "ekauth:user"
}
 
const test = {
  API_URL: "http://srv.gruporecover.com:8002/v1",
  AUTH_URL: "http://srv.gruporecover.com:8002",
  TOKEN_KEY: "auth:token",
  REFRESH_KEY: "auth:refreshToken",
  EXP_KEY: "auth:expiryDate",
  USER_INFO: "auth:user",
}

const prod = {
  API_URL: "https://lexy.mra-legal.com:8002/v1",
  AUTH_URL: "https://lexy.mra-legal.com:8002",
  TOKEN_KEY: "auth:token",
  REFRESH_KEY: "auth:refreshToken",
  EXP_KEY: "auth:expiryDate",
  USER_INFO: "auth:user"
}

export const selectVariables = () => {
  switch (process.env.NODE_ENV) {
      case 'production':
          return prod
      case 'test':
      case 'stage':
          return test
      default:
          return dev
  }
}

export const TYPES = {
  APP_INIT: "APP_INIT",
  APP_SET_USER: "APP_SET_USER",
  APP_SET_HEADER: "APP_SET_HEADER",
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_FAILURE: "AUHT_FAILURE",
  AUTH_CLEAN: "AUTH_CLEAN",
  AUTH_SET_TOKEN: "AUTH_SET_TOKEN",  
  FETCH_DATA: "FETCH_DATA",
  SAVE_DATA: "SAVE_DATA",
  PUT_DATA: "PUT_DATA",
  UPDATE_DATA: "UPDATE_DATA",
  DELETE_DATA: "DELETE_DATA"
}

export const spanish = {
  dayNames: ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sabado"],
  monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio", "Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
}

export const spaStrings = {
  'every': 'cada',
  'until': 'hasta',
  'day': 'dia',
  'days': 'dias',
  'week': 'semana',
  'weeks': 'semanas',
  'month': 'mes',
  'months': 'meses',
  'year': "año",
  'years': "años",
  'on': 'en',
  'for': 'por',
  'time': "vez",
  'times': "veces"
}

export const getQueryStringParams = query => {
  return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
            let [key, value] = param.split('=')
            if(value.charAt(0) === "{" || value.charAt(0) === "["){
              params[key] = JSON.parse(decodeURIComponent(value))
            }else{
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
            }           
            return params
          }, {})
      : {}
};

export const updateArrayObject = (arr, obj, key) => {
  const index = arr.findIndex(x => x.id === obj.id)
  if(index >= 0){
    return [
      ...arr.slice(0,index),
      Object.assign({}, arr[index], obj),
      ...arr.slice(index+1)
    ]
  }
  return [...arr, obj].sort((a,b) => a[key].localeCompare(b[key]))        
}

export const stateSnake = s => s.split("_").map(w => w.toUpperCase()).join(" ")

export const caseColor = s => {
  switch(s){
    case "nuevo":
      return "#87d068"
    case "para_facturar":
      return "#108ee9"
    case "facturado":
      return "lime"
    case "bloqueado":
      return "#f50"
    case "sin_movimiento":
      return "#9f9f9f"
    default:
      return "#000"
  }
}

export const caseState =  [
  { text: 'Nuevo', value: 0 },
  { text: 'Sin Movimiento', value: 1 },
  { text: 'Cerrado', value: 2 },
  { text: 'Anulado', value: 3 },
  { text: 'Facturado', value: 4 },
  { text: 'Para Facturar', value: 5 },
  { text: 'Pagado', value: 6 },
  { text: 'Bloqueado', value: 7 }
]

export const stampState =  [
  { text: 'Solicitado', value: 1 },
  { text: 'Entregado', value: 2 },
  { text: 'Devuelto', value: 3 },
  { text: 'Atrasado', value: 4 }
]

export const stampColor = s => {
  switch(s){
    case "entregado":
      return "green"
    case "devuelto":
      return "gray"
    case "solicitado":
      return "blue"      
    case "atrasado":
      return "maroon"
    default:
      return ""
  }
}

export const feeColor = s => {
  switch(s){
    case "pagado":
      return "green"
    case "cobrando":
      return "yellowgreen"
    case "anulado":
      return "gray"
    case "pendiente_pago":
      return "blue"
    default:
      return ""
  }
}


export const feeState =  [
  { text: 'Pendiente Pago', value: 1 },
  { text: 'Anulado', value: 3 },
  { text: 'Cobrando', value: 4 },
  { text: 'Pagado', value: 5 }
]

export const expenseColor = s => {
  switch(s){
    case "nuevo":
      return "cyan"
    case "pagado":
      return "green"
    case "cobrado":
      return "yellowgreen"
    case "anulado":
      return "maroon"
    case "pendiente_pago":
      return "blue"
    case "solicitado":
      return "teal"
    default:
      return ""
  }
}

export const expenseState = [
  { text: 'Nuevo', value: 0},
  { text: 'Pendiente de Pago', value: 1 },
  { text: 'Solicitado', value: 2 },
  { text: 'Anulado', value: 3 },
  { text: 'Cobrado', value: 4 },
  { text: 'Pagado', value: 5}
]

export const expenseOfficeState = [
  { text: 'Nuevo', value: 0},
  { text: 'Pendiente de Pago', value: 1 },
  { text: 'Anulado', value: 3 },
  { text: 'Cobrado', value: 4 },
  { text: 'Pagado', value: 5}
]


export const expenseRequestColor = s => {
  switch(s){
    case "liquidado":
      return "green"
    case "liquidado_parcial":
      return "lime"
    case "anulado":
      return "red"      
    case "pendiente":
      return "orange"
    case "solicitado":
      return "blue"
    case "aprobado":
      return "cyan"
    default:
      return ""
  }
}
export const expenseRequestState = [
  { text: 'Solicitado', value: 1 },
  { text: 'Otro', value: 2 },
  { text: 'Aprobado', value: 3 },
  { text: 'Liquidado Parcial', value: 4 },
  { text: 'Liquidado', value: 5 },
  { text: 'Anulado', value: 6 },
  { text: 'Pendiente', value: 7 }
]

export const styleContent = {
  content: {
    marginTop: 5,
    padding: 24, 
    background: '#fff', 
    minHeight: "85vh" 
  },
  header: {
    margin: "0px 0px 5px 0px",
    padding: "10px 20px 10px",
    background: "#fff"
  },
  headerLink: {
    color: '#1f1f1f !important',
    fontWeight: 'bold'
  }
}

export const styleDescription = {
  contentStyle: {        
      border: "1px solid rgb(47 76 89 / 20%)"
  },
  labelStyle: {
      backgroundColor: "#bae2e2", 
      border: "1px solid rgb(47 76 89 / 20%)"
  }
}

export const toolbar_editor = [
  ['bold', 'italic', 'underline','strike', 'blockquote'],
  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
]

export const write_access = ['master','admin']

export const VARS = selectVariables()
